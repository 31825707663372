import * as React from "react"
import { Typography } from "@mui/material";
import { Link } from "gatsby-theme-material-ui";
import { StandardPageContainer } from "../components/reviewskew/standardpage/StandardPageContainer";


const NotFoundPage = () => {
  return (
    <StandardPageContainer title="Not found">
      <Typography variant="h1">
        Page not found 😔
      </Typography>
      
      <Typography>
        This page doesn't appear to exist. This is may be a surprise, or a disappointment. Either way you may have better luck <Link to="/">here</Link>.
      </Typography>
    </StandardPageContainer>
  )
}

export default NotFoundPage
